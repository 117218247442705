<template>
  <component :is="objectData === undefined ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="objectData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching object data
      </h4>
      <div class="alert-body">
        No object found with this object id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-objects-list'}"
        >
          Object List
        </b-link>
        for other objects.
      </div>
    </b-alert>

    <b-tabs
      v-if="objectData"
      pills
    >

      <!-- Tab: Account -->
      <b-tab active>
        <template #title>
          <feather-icon
            icon="ObjectIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Account</span>
        </template>
        <object-edit-tab-account
          :object-data="objectData"
          class="mt-2 pt-75"
        />
      </b-tab>

      <!-- Tab: Information -->
      <b-tab>
        <template #title>
          <feather-icon
            icon="InfoIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Information</span>
        </template>
        <object-edit-tab-information
          class="mt-2 pt-75"
          :object-data="objectData"
        />
      </b-tab>

      <!-- Tab: Social -->
      <b-tab>
        <template #title>
          <feather-icon
            icon="Share2Icon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Social</span>
        </template>
        <object-edit-tab-social
          class="mt-2 pt-75"
          :object-data="objectData"
        />
      </b-tab>
    </b-tabs>
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import objectStoreModule from '../objectStoreModule'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
  },
  setup() {
    const objectData = ref(null)

    const OBJECT_APP_STORE_MODULE_NAME = 'app-object'

    // Register module
    if (!store.hasModule(OBJECT_APP_STORE_MODULE_NAME)) store.registerModule(OBJECT_APP_STORE_MODULE_NAME, objectStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(OBJECT_APP_STORE_MODULE_NAME)) store.unregisterModule(OBJECT_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-object/fetchObject', { id: router.currentRoute.params.id })
      .then(response => { objectData.value = response.data })
      .catch(error => {
        if (error.response.status === 404) {
          objectData.value = undefined
        }
      })

    return {
      objectData,
    }
  },
}
</script>

<style>

</style>
