import axios from '@axios'
import { $api } from '@apiConfig'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // eslint-disable-next-line no-unused-vars
    fetchObjectsCount(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${$api.app.object}/count`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchObjects(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get($api.app.object, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchObject(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${$api.app.object}/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addObject(ctx, objectData) {
      return new Promise((resolve, reject) => {
        axios
          .post($api.app.object, objectData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
